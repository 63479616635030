import axios from "axios";
import i18n from "@/i18n";

export default {
  async getCountries(params) {
    try {
      const res = await axios.get(`https://geo.services.exenzo.com/api/v1/search`, {
        params
      });
      return res.data;
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  }
}
