<template>
  <div>
    <div class="md:grid md:grid-cols-3 md:gap-6"
         :class="{
        [`layout-${layout}`]: layout,
        [`${classNames}`] : classNames
       }"
    >
      <slot name="info">
        <div class="md:col-span-1 form-description">
          <div class="px-4 sm:px-0">
            <h3 v-if="title || $slots.title" class="text-lg font-medium leading-6 text-gray-900 dark:text-white">
              <slot name="title">
                {{ title }}
              </slot>
            </h3>
            <p v-if="description || $slots.description" class="mt-1 text-sm leading-5 text-gray-600 dark:text-gray-300">
              <slot name="description">
                {{ description }}
              </slot>
            </p>
          </div>
        </div>
      </slot>
      <div class="mt-5 md:mt-0 md:col-span-2 form-info">
        <div class="shadow-card sm:rounded-md form__inner" :class="{ 'overflow-hidden' : hideOverflow }">
          <VeeForm ref="veeForm" v-slot="{ errors, handleSubmit, meta }">

            <div
                :class="{
                  'mb-16': fixedFooter
                }"
                class="px-4 py-5 bg-white dark:bg-gray-900 sm:p-6">
              <div :class="gridClasses">
                <slot :handleSubmit="handleSubmit"></slot>
              </div>
            </div>
            <div v-if="showButtons"
                 class="px-4 py-3 flex items-center justify-between sm:px-6 form-footer bg-white border-t border-gray-100 dark:bg-gray-900 dark:border-t dark:border-gray-500"
                 :class="{
                    'bg-gray-50': footerBackground,
                    'fixed-footer pt-4': fixedFooter
              }">
              <div>
                <base-button v-if="showBack"
                             variant="primary-link"
                             class="mr-2"
                             @click="$router.go(-1)">
                  &#8592; {{ $t(` Back`) }}
                </base-button>
              </div>
              <div>
                <slot name="footer"
                      :valid="Object.keys(errors).length > 0"
                      :handleSubmit="handleSubmit">
                  <slot name="pre-extra-buttons" />
                  <base-cancel-button v-if="showCancel"
                                      class="mr-3"
                                      @click="$emit('cancel')">
                    {{ cancelText || $t('Cancel') }}
                  </base-cancel-button>
                  <base-submit-button v-if="canSubmitForm"
                                      :loading="loading || loadingButton"
                                      :disabled="submitDisabled"
                                      type="button"
                                      @click="onSubmit"
                  >
                    {{ saveText || $t('Submit') }}
                  </base-submit-button>
                  <slot name="extra-buttons"></slot>
                </slot>
              </div>
            </div>

          </VeeForm>
          <slot name="additional-section"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from 'vee-validate';

export default {
  name: 'BaseForm',
  components: {
    Form
  },
  emits: ['submit', 'cancel'],
  props: {
    loading: Boolean,
    loadingButton: Boolean,
    title: String,
    classNames: String,
    description: String,
    cancelText: String,
    saveText: String,
    layout: {
      type: String,
      default: 'default',
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    hideOverflow: {
      type: Boolean,
      default: true,
    },
    canSubmitForm: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    footerBackground: {
      type: Boolean,
      default: false,
    },
    fixedFooter: {
      type: Boolean,
      default: false,
    },
    gridClasses: {
      type: String,
      default: 'grid grid-cols-6 gap-3'
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    async onSubmit(evt) {
      const res = await this.$refs.veeForm.validate()
      if (!res.valid && res.errors) {
        Object.entries(res.errors).forEach(([index, value]) => {
          const fields = ['title', 'Organization', 'City', 'email', 'Education', 'Category']
          const fieldsHaveErrors = fields.some(field => value.includes(field))
          if (fieldsHaveErrors) {
            this.scrollToTop()
          }
          this.$error(value)
        })
        return
      }
      this.$emit('submit', evt)
    },
    scrollToTop() {
      const top = this.$attrs.editMode ? 280 : 90
      document.querySelector('#scrollable-layer').scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
  mounted() {
    document.addEventListener('keyup', event => {
      if (event.code === 'F4') {
        this.onSubmit()
      }
    })
  },
}
</script>
<style lang="scss" scoped>
.layout-modal {
  .form-description {
    @apply hidden;
  }

  .form-info {
    @apply col-span-3;
  }

  .form__inner {
    @apply shadow-none;
  }
}

.layout-vertical {
  .form-info,
  .form-description {
    @apply col-span-3;
  }
}

.fixed-footer {
  @apply fixed bottom-0 left-0 bg-white border-t border-gray-200;
  z-index: 100;
  margin-left: 32px;
  min-width: calc(100% - 64px);
}
</style>
